<template>
  <!-- 新增融资申请弹出框 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1000px"
    :showFooter="false"
    title="新增融资申请"
    class="dialog"
  >
    <div class="addProcess">
      <base-form
        :componentList="addForm"
        :formAttrs="{
          model: addQueryParas,
          labelWidth: '140px',
        }"
        class="formStyle"
        :showBtns="false"
        ref="add"
      >
      </base-form>
    </div>
    <template slot="footer">
      <base-button label="保 存" @click="submit"></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="
          () => {
            this.currentVisible = false;
          }
        "
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>

import baseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { addForm } from '../utils/config'
import { getManager, getBaseUserInfo } from '@/api/sys/user'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import { getDictLists } from '@/filters/fromDict'
// import { supplierApi } from '@/api/companyApi'

export default {
  components: { baseForm, BaseDialog, BaseButton },
  name: 'addEditDialog',
  props: {
    visible: Boolean,
    productOptions: Array,
    gysData: Array
  },
  data () {
    return {
      addQueryParas: { financeTermUnit: '' },
      busiManagerOptions: [], // 业务经理数据
      gysOptions: [] // 供应商数据
    }
  },
  computed: {
    addForm () {
      return addForm(this)
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    // 地区数据
    areasOptions () {
      return getDictLists('BUSINESS_AREA')
    }
  },
  mounted () {
    this.getbusiManagerOptions()
    // this.getGysList()
  },
  watch: {
    currentVisible (val) {
      console.log(this.$options.data(), '====')
      this.$nextTick(() => {
        this.$refs.add.clearValidate()
        this.addQueryParas = this.$options.data().addQueryParas
      })
    }
  },
  methods: {
    // 业务经理busiManager
    async getbusiManagerOptions () {
      this.busiManagerOptions = []
      const res = await getManager({ roleCode: 'ywjl_code' })
      const userInfo = await getBaseUserInfo()
      if (res.data) {
        res.data.forEach((item) => {
          if (item.userId !== userInfo.data.userId) {
            this.busiManagerOptions.push({ busiManagerId: item.userId, busiManagerName: item.nickName })
          }
        })
      }

      // getManager({ roleCode: 'ywjl_code' }).then(res => {
      //   console.log(res.data, '222')
      //   this.busiManagerOptions = res.data.map(item => {
      //     return { busiManagerId: item.userId, busiManagerName: item.nickName }
      //   })
      // })
    },
    // 获取供应商数据
    // getGysList () {
    //   supplierApi.getList().then(res => {
    //     if (res.success) {
    //       this.gysOptions = res.data
    //     }
    //   })
    // },
    // 选时间类型
    changeValidTimeUnit (data) {
      console.log(data)
      this.$set(this.addQueryParas, 'financeTermUnit', data)
    },
    // 选择融资产品
    changeProduct (data) {
      const [obj] = this.productOptions.filter(item => item.keyId === data)
      this.$set(this.addQueryParas, 'finaaceProductName', obj.productName)
    },
    // 选择供应商
    changeGys (data) {
      const [obj] = this.gysData.filter(item => item.keyId === data)
      console.log(obj, '---')
      this.$set(this.addQueryParas, 'gysCompanyName', obj.enterpriseName)
    },
    // 选择地区
    changeArea (data) {
      const [obj] = this.areasOptions.filter(item => item.dictId === data)
      this.$set(this.addQueryParas, 'area', obj.dictName)
    },
    // 选择业务经理
    changelegalManager (data) {
      const outArr = []
      data.forEach(item => {
        const [obj] = this.busiManagerOptions.filter(ele => ele.busiManagerId === item)
        outArr.push(obj.busiManagerName)
      })
      this.$set(this.addQueryParas, 'otherManagerId', data.join(','))
      this.$set(this.addQueryParas, 'otherManagerName', outArr.join(','))
    },
    // 提交新增修改
    submit () {
      this.$refs.add.validate(valid => {
        if (valid) {
          const data = this.addQueryParas
          financeAdmittanceApi.newSaveAdmittance(data).then(res => {
            if (res.code === '0') {
              this.currentVisible = false
              this.$parent.handleFilter()
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog{
  height: 400px;
}
</style>
