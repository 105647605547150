var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{staticClass:"dialog",attrs:{"visible":_vm.currentVisible,"width":"1000px","showFooter":false,"title":"新增融资申请"},on:{"update:visible":function($event){_vm.currentVisible=$event}}},[_c('div',{staticClass:"addProcess"},[_c('base-form',{ref:"add",staticClass:"formStyle",attrs:{"componentList":_vm.addForm,"formAttrs":{
        model: _vm.addQueryParas,
        labelWidth: '140px',
      },"showBtns":false}})],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"label":"保 存"},on:{"click":_vm.submit}}),_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":function () {
          this$1.currentVisible = false;
        }}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }