import BaseSelect from '@/components/common/base-select/base-select.vue'
import Amountinput from '@/components/input/amount-input/amount-input'
import { formatDate } from '@/utils/auth/common'
import termOfValidity from '@/components/packages/term-of-validity/term-of-validity.vue'
import { validateTerm } from '@/utils/validate'
// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      attrs: {
        placeholder: '请选择',
        maxlength: 100,
        options: content.gysData,
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      },
      tag: BaseSelect
    },
    {
      label: '融资产品',
      prop: 'finaaceProductId',
      attrs: {
        placeholder: '请选择',
        options: content.productOptions,
        selectedField: ['keyId', 'productName'],
        filterable: true
      },
      tag: BaseSelect
    },
    {
      label: '审核状态',
      prop: 'reviewStatus',
      attrs: {
        placeholder: '请选择',
        options: content.reviewOptions,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      tag: BaseSelect,
      isHidden: content.fold
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeStatus',
      attrs: {
        placeholder: '请选择',
        options: content.fileMakeOptions,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      tag: BaseSelect,
      isHidden: content.fold
    }

  ]
}
// 表格form
export const dataTable = (context) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      minWidth: '110px'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      align: 'left',
      minWidth: '200px',
      'show-overflow-tooltip': true
    },
    {
      label: '融资产品',
      prop: 'finaaceProductName',
      minWidth: '90px'
    },
    {
      label: '融资金额(元)',
      prop: 'applyAmount',
      minWidth: '110px',
      align: 'right',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '业务经理',
      prop: 'busiManagerName',
      minWidth: '110px'

    },

    // {
    //   label: '风控合规岗',
    //   prop: 'riskManagerName',
    //   minWidth: '110px',
    //   formatter: row => {
    //     return row.riskManagerName ? row.riskManagerName : '--'
    //   }
    // },

    {
      label: '业务区域',
      prop: 'area',
      minWidth: '80px'
    },
    {
      label: '申请时间',
      prop: 'addTime',
      formatter: row => {
        return formatDate(row.addTime, 'YY-MM-DD hh:mm:ss')
      },
      minWidth: '150px'
    },

    {
      label: '审核状态',
      prop: 'reviewStatusName',
      minWidth: '100px',
      formatter: row => {
        return row.reviewStatusName ? row.reviewStatusName : '--'
      }

    },
    {
      label: '文件制作状态',
      prop: 'fileMakeName',
      minWidth: '150px'
    },
    {
      label: '操作',
      prop: 'action',
      fixed: 'right',
      width: '130px',
      align: 'left'
    }
  ]
}
// 新增修改form
export const addForm = (content) => {
  // 融资金额校验
  const validatorApplyAmount = (rule, value, callback) => {
    if (value && value - 10000 < 0) {
      callback(new Error('融资金额需大于等于10000元'))
    } else {
      callback()
    }
  }
  // 融资期限校验
  // const validatorFinanceTerm = (rule, value, callback) => {
  //   if (value) {
  //     if (!content.addQueryParas.financeTermUnit) {
  //       callback(new Error('请选择日期格式'))
  //     } else {
  //       callback()
  //     }
  //   } else {
  //     if (content.addQueryParas.financeTermUnit) {
  //       callback(new Error('请输入日期'))
  //     } else {
  //       callback()
  //     }
  //   }
  // }

  // const validateTerm = (validTimeUnit) => {
  //   return (rule, value, callback) => {
  //     if (value) {
  //       if (validTimeUnit === 'YEAR') {
  //         if (value > 3) {
  //           callback(new Error('选择年,日期大于0小于3'))
  //         } else {
  //           callback()
  //         }
  //       } else if (validTimeUnit === 'MONTH') {
  //         if (value > 24) {
  //           callback(new Error('选择月,日期大于0小于24'))
  //         } else {
  //           callback()
  //         }
  //       } else if (validTimeUnit === 'DAY') {
  //         if (value > 365) {
  //           callback(new Error('选择日,日期大于0小于365'))
  //         } else {
  //           callback()
  //         }
  //       }
  //     } else {
  //       callback()
  //     }
  //   }
  // }
  const span = 8
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      span,
      attrs: {
        placeholder: '请输入',
        maxlength: 32
      }
    },
    {
      label: '融资产品',
      prop: 'finaaceProductId',
      rules: [{ required: true, message: '请选择融资产品', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.productOptions,
        selectedField: ['keyId', 'productName'],
        filterable: true
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeProduct(data)
        }
      }
    },
    {
      label: '供应商名称',
      prop: 'gysId',
      rules: [{ required: true, message: '请输入供应商名称', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.gysData,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true,
        maxlength: 100
      },
      on: {
        change: (data) => {
          content.changeGys(data)
        }
      },
      span,
      tag: BaseSelect
    },
    {
      label: '业务所属区域',
      prop: 'areaId',
      rules: [{ required: true, message: '请选择业务所属区域', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.areasOptions,
        selectedField: ['dictId', 'dictName']
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeArea(data)
        }
      }
    },
    {
      label: '融资金额(元)',
      prop: 'applyAmount',
      rules: [
        { required: false, message: '', trigger: ['blur', 'change'] },
        { validator: validatorApplyAmount, trigger: 'blur' }
      ],
      attrs: {
        type: 'Y',
        placeholder: '请输入'
      },
      tag: Amountinput,
      span
    },
    {
      label: '融资期限',
      prop: 'financeTerm',
      rules: [
        { required: false, message: '', trigger: 'change' },
        { validator: validateTerm(content.addQueryParas.financeTermUnit), trigger: 'change' }
      ],
      attrs: {
        dictType: 'MM',
        placeholder: '请输入有效期',
        maxlength: 5,
        type: 'ZS',
        validTimeUnit: content.addQueryParas.financeTermUnit
      },
      on: {
        update (val) {
          content.changeValidTimeUnit(val)
        }
      },
      span,
      tag: termOfValidity

    },
    {
      label: '其他业务经理',
      prop: 'otherManagerData',
      attrs: {
        multiple: true,
        placeholder: '请选择',
        options: content.busiManagerOptions,
        selectedField: ['busiManagerId', 'busiManagerName'],
        filterable: true
      },
      span,
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changelegalManager(data)
        }
      }
    }

  ]
}
// 详情from
export const formInfoconfig = (content) => {
  const span = 8
  return [
    {
      label: '申请主体',
      prop: 'gysCompanyName',
      span
    },
    {
      label: '融资状态',
      prop: 'statusName',
      span
    },
    {
      label: '申请金额',
      prop: 'applyAmount',
      span
    },
    {
      label: '风控经理',
      prop: 'riskManagerName',
      span

    },
    {
      label: '法务经理',
      prop: 'legalManagerName',
      span

    },
    {
      label: '业务所属区域',
      prop: 'area',
      span

    },
    {
      label: '融资产品',
      prop: 'finaaceProductName',
      span

    },
    {
      label: '资金方',
      prop: 'capitalSideName',
      span
    },

    {
      label: '备注',
      prop: 'memo',
      span: 24,
      showAll: true
    }

  ]
}
